import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/user/context';
import {
  isLoggedIn,
  drupalUserInfo,
  drupalUserParagraph,
  clearLocalStorage,
  getWithExpiry
} from '../../util/auth/drupal';

/* This check is to ensure that this code gets executed in browser because
 * If we run this code without this check your gatsby develop will fail as it won't be able
 * to access localStorage on build time
 */
const useAuth = (userStatus) => {
  const [isAuth, setIsAuth] = useState(userStatus);
  const { state, userLogin, userLogout } = useContext(UserContext);
  const { user } = state;

  useEffect(() => {
    checkLogin();
  }, [user.isLoggedIn]);

  const checkLogin = async () => {
    let name = await getWithExpiry('name') || ``;

    try {
      const res = await isLoggedIn();

      if (res && user.isLoggedIn) {
        return setIsAuth(true);
      } else if (res && user.isLoggedIn === false && name) {
        const userData = await drupalUserInfo(name);

        if (userData) {
          // with user data, pull paragraph data
          /* let userDataWithParagraph = userData.data.filter((d) => d?.relationships?.field_address_paragraphs?.data[0]?.id);
          userDataWithParagraph = userDataWithParagraph.sort((a,b) => {
              return Date.parse(a?.attributes?.created) > Date.parse(b?.attributes?.created);
          }).reverse()
                                                     
          if (userDataWithParagraph && userDataWithParagraph.length) {
            const paragraphID = userDataWithParagraph[0]?.relationships?.field_address_paragraphs?.data[0]?.id;
            if (paragraphID) {
              const userParagraph = await drupalUserParagraph(paragraphID);
              userData.data = [];
              userData.data[0] = {...userDataWithParagraph[0]};
              userData.data[0].relationships.field_address_paragraphs.data = {...userParagraph};
            }
          } */
          userLogin(userData);
          setIsAuth(true);
        } else {
          console.log('Login failed due to inconsistent user data')
          userLogout();
          return setIsAuth(false);
        }
      } else {
        userLogout();
        return setIsAuth(false);
      }
    } catch (error) {
      userLogout();
      clearLocalStorage();
      setIsAuth(false);
      console.log(`Error: ${error.message}`);
    }
  };

  return [isAuth];
};

export default useAuth;
